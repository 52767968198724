@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import './variables';

* {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif !important;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  background-color: #ffff;
}
html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar-track {
  padding: 2px 0;
  border-radius: 10px;
  background-color: $grayLight;
}

::-webkit-scrollbar {
  width: 10px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $grayDark;
}
// --------- LOGIN STYLES ---------

.login-container {
  background-color: $darkBackground;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-box {
    width: 500px;
    box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px;

    @media only screen and (max-width: 1500px) {
      width: 500px;
    }
    @media only screen and (max-width: 768px) {
      width: 400px;
    }
  }

  .header {
    background-color: $grayDark;
    padding: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    h2 {
      text-transform: uppercase;
      letter-spacing: 1.7px;
      text-align: center;
      color: #000;
    }
  }

  .body {
    padding: 8px;
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .form {
      margin: 10px auto;
      padding-bottom: 30px;
      width: 70%;

      label {
        font-weight: bold;
        margin-bottom: 8px;
      }
      div:first-child {
        margin-bottom: 12px;
      }

      .button {
        margin-top: 10px;
      }
    }

    .google-sign {
      margin: 20px auto;
      width: 70%;
      text-align: center;
    }

    .or_signin-with {
      margin: 25px 0;
      p {
        position: relative;
        z-index: 1;
        overflow: hidden;
        text-align: center;

        &::before,
        &::after {
          content: '';
          width: 150px;
          height: 1px;
          display: inline-block;
          background-color: $grayDark;
          vertical-align: 0.3em;
          margin: 0 -100%;
        }

        &::before {
          margin-right: 0.75em;
        }

        &::after {
          margin-left: 0.75em;
        }
      }
    }
  }
}

.navbar-user {
  width: 24px;
  height: 24px;
  background-color: $grayDark;
  border-radius: 100%;
}

.drawer-container_closed,
.drawer-container_open {
  padding: 15px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
}

.drawer-container_open {
  background-color: rgba(255, 255, 255, 0.5);
}

.drawer-list .drawer-items:hover {
  border-left: 1px solid black !important;
  background-color: $grayDark;
}

.drawer-list .drawer-item_open:hover {
  border-left: 1px solid black !important;
}

.drawer-list .drawer-selected {
  border-left: 1px solid black !important;
}

.drawer-list_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .bottom {
    height: 80px;
  }
}

.status_row {
  border-radius: 56px;
  text-align: center;
  width: 70%;
  padding: 6px 5px;
  text-transform: capitalize;
}

.history_status {
  background-color: #f0f0f0;
  border-radius: 56px;
  text-align: center;
  font-size: 15px;
  color: #646464;
  padding: 6px 5px;
  text-transform: capitalize;
}

.first_column {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 20;
  border-right: none !important;
  box-shadow: inset -2px 0px 0 #d9d9d9, inset 0 0px 0 #d9d9d9;
}

.first_column-prospect {
  left: 0;
  background-color: white;
  border-right: none !important;
  box-shadow: inset -2px 0px 0 #d9d9d9, inset 0 0px 0 #d9d9d9;
}

.skeleton_first-column {
  width: 450px;
  padding: 20px 0;
  @media only screen and (max-width: 1185px) {
    width: 200px;
  }
  @media only screen and (max-width: 900px) {
    width: 100px;
  }
}

.skeleton_first-column-user {
  padding: 15px 85px;
  @media only screen and (max-width: 1185px) {
    padding: 15px 65px;
  }
  @media only screen and (max-width: 900px) {
    padding: 15px 50px;
  }
}

.table-link_text {
  font-size: 14px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 450px;

  @media only screen and (max-width: 1185px) {
    max-width: 200px;
  }
  @media only screen and (max-width: 900px) {
    max-width: 100px;
  }
}

.table-link_text-prospect {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: 450px;
  display: block;

  @media only screen and (max-width: 1185px) {
    max-width: 200px;
  }
  @media only screen and (max-width: 900px) {
    max-width: 100px;
  }
}

.table-link_description-prospect {
  max-width: 450px;
  display: block;

  @media only screen and (max-width: 1185px) {
    max-width: 200px;
  }
  @media only screen and (max-width: 900px) {
    max-width: 100px;
  }
}

.table_overflow-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
}

.menu_text {
  font-size: 13px !important;
  color: #373737 !important;
}

.table_container {
  overflow: 'auto' !important;
  &::-webkit-scrollbar-track {
    padding: 2px 0;
    border-radius: 10px;
    background-color: $grayLight;
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $grayDark;
  }
}
.column-row:hover {
  background-color: #f7f7f7 !important;
}

.column-row:hover .first_column,
.column-row:hover .first_column-prospect {
  background-color: #f7f7f7 !important;
}

.Mui-selected,
.Mui-selected .first_column,
.Mui-selected .first_column-prospect {
  background-color: #f0f0f0 !important;
}

.filters-container {
  width: 13%;
  min-width: 12%;
  max-width: 13%;

  @media only screen and (max-width: 1200px) {
    width: 22%;
  }

  @media only screen and (max-width: 900px) {
    width: 30%;
  }
}

.tabs-tab_selected {
  background-color: white !important;
  color: #000000 !important;
  text-transform: capitalize !important;
  font-size: 13px !important;
  font-weight: bold !important;
}

.tabs-tab_notselected {
  color: rgba(0, 0, 0, 0.4) !important;
  text-transform: capitalize !important;
  font-size: 13px !important;
  font-weight: bold !important;
}

.grid_container {
  display: grid;
  grid-template-columns: 1fr 5.5fr;

  @media only screen and (max-width: 1500px) {
    grid-template-columns: 1fr 4fr;
  }

  @media only screen and (max-width: 1300px) {
    grid-template-columns: 1fr 3fr;
  }

  @media only screen and (max-width: 950px) {
    grid-template-columns: 1fr 2.5fr;
  }

  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr 2fr;
  }
}

.grid_audience-aff {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  height: calc(100vh - 131.7px);

  .columns {
    height: 100%;
    overflow-y: auto;
  }

  .column-border {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.grid_client-sites {
  display: grid;
  width: 100%;
  overflow-x: auto;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  height: calc(100vh - 198px);
  grid-auto-flow: column;

  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-auto-columns: minmax(250px, 1fr);
  }

  // @media only screen and (max-width: 770px) {
  //   grid-template-columns: 1fr 1fr 1fr 1fr;
  // }

  .columns {
    height: 100%;
    overflow-y: auto;
  }

  .column-border {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.grid_container_settings {
  display: grid;
  grid-template-columns: 1fr 5.5fr;

  @media only screen and (max-width: 1250px) {
    grid-template-columns: 1fr 3fr;
  }
  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr 3.5fr;
  }

  @media only screen and (max-width: 770px) {
    grid-template-columns: 1fr 2.5fr;
  }
}

.left_columns,
.right_columns {
  max-height: 100vh;
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    padding: 2px 0;
    border-radius: 10px;
    background-color: $grayLight;
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $grayDark;
  }
}

.hide-column {
  transition: opacity 0.3s linear !important;
  opacity: 0;
  display: none !important;
}

.present-column {
  opacity: 1;
  transition: opacity 1s linear;
}

.remove-line:before {
  height: 0px !important;
}

.form-grids {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.form-grids-points {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;
}

.whole-grid {
  grid-column: 1 / span 2;
}

.whole-grid_three {
  grid-column: 1 / span 3;
}

.mb-2 {
  margin-bottom: 10px;
}

.mb-1 {
  margin-bottom: 5px;
}

.select-error .MuiSelect-select {
  border-color: #d32f2f !important;
}

.user-select-forms {
  width: 20px;
  height: 20px;
  margin-right: 6px;
  border-radius: 100%;
  background: #d9d9d9;
}

.status-select-forms {
  width: 10px;
  height: 10px;
  background: #7bb0ff;
  border-radius: 100%;
  margin-right: 6px;
}

.list-first-button {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.list-buttons {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.list_item-parent:hover .list_item-button {
  opacity: 1 !important;
}

.list_item-button {
  opacity: 0 !important;
}

.sort_direction {
  transform: rotate(180deg);
}

.drawer-width {
  overflow-x: hidden;
  width: 815px;

  @media only screen and (max-width: 1024px) {
    width: 710px;
  }
}

.bold {
  font-weight: 700;
}

.profile-responsive {
  height: 820px;

  @media only screen and (max-width: 1550px) {
    height: 730px;
  }
  @media only screen and (max-width: 1120px) {
    height: 630px;
  }
}

.bold-letter {
  font-weight: 700;
}

.site_access-responsive {
  height: 700px;

  @media only screen and (max-width: 1120px) {
    height: 630px;
  }
}

.alphscroll {
  position: fixed;
  top: 310px;
}

.width-fixed {
  position: fixed;
  z-index: 30;
  background-color: white;
  width: 40.5%;

  @media only screen and (max-width: 2560px) {
    width: 41.1%;
  }

  @media only screen and (max-width: 1980px) {
    width: 40.8%;
  }

  @media only screen and (max-width: 1800px) {
    width: 40.5%;
  }

  @media only screen and (max-width: 1530px) {
    width: 40.4%;
  }

  @media only screen and (max-width: 1442px) {
    width: 40.4%;
  }

  @media only screen and (max-width: 1442px) {
    width: 40.4%;
  }

  @media only screen and (max-width: 1430px) {
    width: 40.2%;
  }

  @media only screen and (max-width: 1320px) {
    width: 39.9%;
  }

  @media only screen and (max-width: 1260px) {
    width: 35%;
  }

  @media only screen and (max-width: 1100px) {
    width: 35%;
  }

  @media only screen and (max-width: 1024px) {
    width: 36.3%;
  }
}

.column-inactive,
.column-inactive .first_column {
  background-color: #f9f9f9;
}

.column-inactive .table-link_text {
  color: rgba(55, 55, 55, 0.6);
}

.mail-merge {
  margin-right: 5px;
  background-color: white;
  border-radius: 20px;
  outline: none;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.5px;
  cursor: pointer;
  border: 1px solid #d7d7d7;
  transition: all 0.3s;
}

.mail-merge:hover {
  background-color: #d9d9d9;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.8px;
  font-size: 0.875rem;
  padding-left: 7px;

  .page-num {
    padding: 5px 9px;
    cursor: pointer;
    border-radius: 4px;
    line-height: 1.43;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .page-active {
    background-color: rgba(0, 0, 0, 0.04);
    font-weight: 600;
  }
  .page-dots {
    cursor: pointer;
    padding: 5px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .page-disabled {
    color: rgba(0, 0, 0, 0.4);
    cursor: default;

    &:hover {
      background-color: white !important;
    }
  }
}

.label-avatar {
  display: flex;
  cursor: pointer;
}

.avatar-upload {
  display: none;
}

.min-links-field {
  font-size: 14px;
  font-weight: 400;
  color: #373737;
  padding: 4px 3px;
  border: 2px solid #d7d7d7;
  border-radius: 4px;
}

.min-links-field:focus {
  outline: none;
  border: 2px solid #ababab;
}

.required-fields_dot {
  color: red;
  font-weight: 700;
}
